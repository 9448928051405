import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Row, Input, Space, Badge } from "antd";
import {Button as MatButton} from "@mui/material"
import qs from "qs";
import { useNavigate } from "react-router-dom";
import { ExportOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Layout from "../../components/Layout";
import AntDropdown from "../../components/CustomDropdown/AntDropdown";
import noDataLogo from "../../assets/images/no-data.png";
import color from "../../theme/color";
import CreateSingleUserModal from "../../components/Modal/User/CreateSingleUser";
import AntTable from "../../components/CustomTable/AntTable";
import { customBaseUrl, httpDelete, httpGet, httpPost } from "../../store/http";
import CreateBulkUser from "../../components/Modal/User/CreateBulkUser";
import UserActionsModal from "../../components/Modal/User/UserActions";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import {
  Box,
  Select,
  Grid,
  Paper,
  MenuItem,
  FormControl,
  InputBase,
} from "@mui/material";
import DeactivateUsers from "../../components/Modal/User/DeactivateUsers";
import { useMatStyles } from "./style";
import { transformName } from "../../utils/helper";
import { hideLoader, showLoader } from "../../utils/loader";
import { downloadFile } from "../../utils/fileDownloader";
import Swal from "sweetalert2";
import { getColumnSearchProps } from "../../components/CustomTableSearch";
import CreateBulkForm from "../../components/Modal/User/CreateBulkForm";
import { NotificationManager } from "react-notifications";
import EmailInvite from "./modals/emailInvite";
import SocialInvites from "./modals/socialInvites";
import ExportCSV from "./export";
import VerifyUser from "./verify";
import PinModal from "../../components/Modal/Pin/PinModal";
import { Lock } from "@mui/icons-material";
import moment from "moment";
import { CSVLink } from "react-csv";
import generatePDF from "./generatePdf";
import UserProfiles from "./profiles";
const Index = () => {
  const navigate = useNavigate();
  const history = useNavigate();
  const roles = localStorage.getItem('roles');
  const [users, setUsers] = useState([]);
  const [showCreateUser, setShowCreateUser] = useState(false);
  const [showBulkSms, setShowBulkSms] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showCreateUserExcel, setShowCreateUserExcel] = useState(false);
  const [showCreateUserForms, setShowCreateUserForms] = useState(false);
  const [showSocial, setShowSocial] = useState(false)
  const [showUserAction, setShowUserAction] = useState(false);
  const [showDeactivateUser, setShowDeactivateUser] = useState(false);
  const [searchField, setSearchField] = useState(false);
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 10,
  });
  const [filteredDate, setFilteredDate] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [selectedRecord, setRecord] = useState([]);
  const [isSmUsers, setIsSmUsers] = useState(false);
  const [searchData, setSearchData] = useState({
    searchText: "",
    searchedColumn: 0,
  });
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [date, setDate] = useState(new Date());
  const styles = useMatStyles();
  const [search, setSearch] = useState({
    searchBy: "lastName",
    searchText: "",
  });
  const [totalRegisteredUsers, setTotalRegisteredUsers] = useState(0);
  const [totalActiveUsers, setTotalActiveUsers] = useState(0);
  const [totalInactiveUsers, setTotalInactiveUsers] = useState(0);
  const [totalCorporateUsers, setTotalCorporateUsers] = useState(0);
  const [totalIndividualUsers, setTotalIndividualUsers] = useState(0);
  const [showExport, setShowExport] = useState(false);
  const [showProfiles, setShowProfile] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [showVerifyAccount, setShowVerifyAccount] = useState(false);
  const [otp, setOtp] = useState('')
  const [phone, setPhone] = useState('')

  useEffect(async () => {
    showLoader();
    let totalRegisteredUsersResponse = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/dashboard/total-registered-users`
    );
    if (totalRegisteredUsersResponse !== null) {
      setTotalRegisteredUsers(totalRegisteredUsersResponse);
    }
    let totalActiveUsersResponse = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/dashboard/active-users`
    );
    if (totalActiveUsersResponse !== null) {
      setTotalActiveUsers(totalActiveUsersResponse);
    }
    let totalInactiveUsersResponse = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/dashboard/in-active-users`
    );
    if (totalInactiveUsersResponse !== null) {
      setTotalInactiveUsers(totalInactiveUsersResponse);
    }
    let totalCorporateUsersResponse = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/dashboard/total-registered-cooperate-users`
    );
    if (totalCorporateUsersResponse !== null) {
      setTotalCorporateUsers(totalCorporateUsersResponse);
    }
    let totalIndividualUsersResponse = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/dashboard/total-registered-individual-users`
    );

    setTotalIndividualUsers(totalIndividualUsersResponse);

    hideLoader();
  }, [totalIndividualUsers]);

  console.log(
    showBulkSms,
    showEmail,
    showCreateUserExcel,
    showCreateUserForms
  );

  const inviteUserOptions = ["Via Social Media",  "Via Email"];
  const handleInviteUsersOption = (item) => {
    if (item === "Via Social Media") setShowSocial(true);
    if (item === "Via SMS") setShowBulkSms(true);
    if (item === "Via Email") setShowEmail(true);
  };
  const createUserOptions = [
    "Create Single User",
    "Upload Excel Sheet to Create",
    "Create Users with Forms",
  ];

  const handleCreateUserDropdownAction = (item) => {
   if(roles.indexOf('ROLE_ADMIN_OWNER')!==-1 ||roles.indexOf('ROLE_ADMIN_APP') !==-1 ||roles.indexOf('ROLE_ADMIN_INITIATOR') !==-1||roles.indexOf('ROLE_ADMIN_SUPER') !==-1){

    if (item === "Create Single User") setShowCreateUser(true);
    if (item === "Upload Excel Sheet to Create") setShowCreateUserExcel(true);
    if (item === "Create Users with Forms") setShowCreateUserForms(true);
   }else{
    NotificationManager.error('Not authorize')
   }
  };
  const downloadTemplateOptions = [
    "Download Template for Bulk Deactivation/Activation",
    "Bulk User Creation Template for Corporate",
    "Bulk User Creation Template for Individual",
  ];
  const handleDownloadTemplateDropdownAction = (item) => {
    if (item === "Download Template for Bulk Deactivation/Activation")
      downloadTemplates("deactivate");
    if (item === "Bulk User Creation Template for Corporate")
      downloadTemplates("corporate");
    if (item === "Bulk User Creation Template for Individual")
      downloadTemplates("individual");
  };

  const downloadTemplates = (type) => {
    if (type == "deactivate") {
      console.log("deactivate");
      const httpLink = `${customBaseUrl.authUrl}/api/v1/admin/bulk/account-activation/bulk-user-excel`;
      downloadFile(httpLink);
    } else if (type == "individual") {
      console.log("individual");
      const httpLink = `${customBaseUrl.authUrl}/api/v1/admin/download/bulk-user-excel?isCorporate=false`;
      downloadFile(httpLink);
    } else {
      console.log("corporate");
      const httpLink = `${customBaseUrl.authUrl}/api/v1/admin/download/bulk-user-excel?isCorporate=true`;
      downloadFile(httpLink);
    }
  };
  const dropDownOptions=(row) => [
    "Manage Profile",
    "Manage Team Members",
    "Manage Transaction",
    "Manage Kyc",
    "Login Tracker",
    "Login Log",
    "Activity Log",
    "Reset Credentials",
    "Deactivate User",
    "Delete User",
    "Block User",
    "Lock Transaction"

  ];

  const getRandomuserParams = (params) => ({
    size: params.pagination.pageSize ?? pagination.pageSize,
    page: params.pagination.current ?? pagination.current,
  });

  const handleGetUsers = async (params = {}) => {
    params.preventDefault();
    setLoading(true);
    showLoader();
    // https://services.staging.wayabank.ng/auth-service/api/v1/admin/users?page=0&size=20
    /*const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/admin/users?${qs.stringify(
        getRandomuserParams(params)
      )}&sortBy=id&sortOrder=ASC`
    );*/
    const date = new Date();
    const month = date.getMonth();
    date.setMonth(month - 1);  

    const res = await httpGet(
      `${isSmUsers ? customBaseUrl?.smAuthUrl : customBaseUrl.authUrl}/api/v1/admin/users-list?fromDate=${moment(filteredDate?.fromdate ?? date).format('YYYY-MM-DD') }&toDate=${moment(filteredDate?.todate?? (new Date()).setMonth(month + 1)).format('YYYY-MM-DD')}`
    );
    console.log(res);
    setLoading(false);
    if(res?.status===false) Swal.fire(res?.message)
    hideLoader();
    /*if (res?._embedded?.userResponse === undefined) {
      setLoading(true);
      let query = getRandomuserParams(params);
      const totalRecords = res?.page?.totalElements;
      const previousPagesRecords = (res?.page.number - 1) * res?.page.size;
      const remainingRecords = totalRecords - previousPagesRecords;
      if (remainingRecords > 0) {
        query.size = remainingRecords;

        const res2 = await httpGet(
          `${customBaseUrl.authUrl}/api/v1/admin/users?${qs.stringify(
            query
          )}&sortBy=id&sortOrder=ASC`
        );
        setLoading(false);
        setUsers(res2?._embedded?.userResponse);

        setPagination({
          ...params.pagination,
          total: res2.page.totalElements,
        });
      } else {
        NotificationManager.warning("no more records");
        return;
      }
    } else {
      const filter = clean(filteredData);

      const filtered = res?.data?.filter(e=>  {
        //  console.log(filteredData)
        return Object.keys(filter).every(key => {
          return filter[key] === e[key];
        });
          //if( e?.tranCategory?.includes(filteredData?.tranCategory) && e?.partTranType?.includes(filteredData?.partTranType) ) return e
        
      });
      console.log('filtered', filtered)
      setUsers(filtered);
      setPagination({
        ...params.pagination,
        total: filtered?.length,
      });
    }*/
    if(res?.status){
      const filter = clean(filteredData);
        setUsers([]);
      const filtered = res?.data?.filter(e=>  {
        console.log('fd', filteredData)
        return Object.keys(filter).every(key => {
          return (key === 'firstName' || key === 'surname') ? filter[key]?.toLowerCase() === e[key]?.toLowerCase() : filter[key] === e[key];
        });
          //if( e?.tranCategory?.includes(filteredData?.tranCategory) && e?.partTranType?.includes(filteredData?.partTranType) ) return e
        
      });
      console.log('filtered', filtered)
      if(filtered?.length < 1) NotificationManager.error('Record not found' );
      setUsers(filtered);
      setPagination({
        ...params.pagination,
        total: filtered?.length,
      });
      hideLoader();
    } else {
      hideLoader()
      console.log(res);
    }
    hideLoader();
  };

  const handleDeleteUser = async (id) => {
  if(roles.indexOf('ROLE_ADMIN_OWNER') ===-1 ||roles.indexOf('ROLE_ADMIN_APP') ===-1 ||roles.indexOf('ROLE_ADMIN_INITIATOR') ===-1||roles.indexOf('ROLE_ADMIN_SUPER') !==-1) return NotificationManager.error('not authrize')

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.value) {
        showLoader();
        const res = await httpDelete(
          `${customBaseUrl.authUrl}/api/v1/user/delete/${id}`
        );
        if (res.status === true) {
          hideLoader();
          NotificationManager.success("User Deleted Successfully");
          handleGetUsers({pagination});
        }
        else{
          hideLoader();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.message,
          })
        }
      }
    });
  };
  useEffect(async (params={}) => {
    setLoading(true);
    showLoader();
   
    const date = new Date();
    const month = date.getMonth();
    date.setMonth(month - 1);

    const res = await httpGet(
      `${isSmUsers ? customBaseUrl?.smAuthUrl :customBaseUrl.authUrl}/api/v1/admin/users-list?fromDate=${moment(filteredDate?.fromdate ?? date).format('YYYY-MM-DD') }&toDate=${moment(filteredDate?.todate?? (new Date()).setMonth(month + 1)).format('YYYY-MM-DD')}`
    );
    console.log(res);
    setLoading(false);
    if(res?.status===false) Swal.fire(res?.message)
    hideLoader();
    
    if(res?.status){
      
      
      console.log('filtered2', res?.data)
      setUsers(res?.data);
      setPagination({
        ...params.pagination,
        total: res?.data?.length,
      });
      hideLoader();
    } else {
      hideLoader()
      console.log(res);
    }
    hideLoader();
  },[]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleResetCredentials = (user) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you are about to send reset OTP to this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reset!",
    }).then((result) => {
      if (result.value) {
        const res = httpGet(
          `${customBaseUrl.authUrl}/api/v1/password/change-password/byEmail?email=${user.email}`
        );
        if (res) {
          NotificationManager.success("Credentials Reset Successfully");
          // handleGetUsers({pagination});
        }
      }
    });
  }

  const handleRToggleLock = (userId, record) => {
    Swal.fire({
      title: "Are you sure?",
      text: `you are about to ${record?.accountNonLocked?"UnLock" : "Lock"} this user!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `${record?.accountNonLocked?"Yes, unblock!": "Yes, lock"}`,
    }).then((result) => {
      if (result.value) {
        const res = httpPost(
          `${customBaseUrl.authUrl}/api/v1/admin/users/lock-toggle/un-lock?userId=${userId}`,{}
        );
        if (res) {
          NotificationManager.success(res?.message);
          // handleGetUsers({pagination});
        }
      }
    });
  }
  const verify = async (email) => {
    showLoader()
    setPhone(email);
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/auth/resend-otp/signup/${email}`
    );
    if (res.status) {
      hideLoader();
      Swal.fire("Done", res.message, "success").then(() => {
        setShowVerify(false);
        setShowVerifyAccount(true);
      });
    } else {
      hideLoader();
      Swal.fire('error', res?.message)
    }
  }
  const completeVerification = async () => {
    showLoader();
    const postData = {
      otp: Number(otp),
      phoneOrEmail: phone,
    };
    const res = await httpPost(`${customBaseUrl.authUrl}/api/v1/auth/verify-otp`,
      postData,
    );
    if (res.status) {
      hideLoader();
      setOtp("");
      setPhone("");
      setShowVerifyAccount(false);
      // setShowVerifyOtp(false);
      Swal.fire("Done", res.message, "success")
    } else {
      hideLoader();
      setOtp("");
      Swal.fire("Oops!", res.message, "error");
    }
  };
  const handleDropdownAction = (item, id, record) => {
    setSelectedId(id);
    setRecord(record);
    localStorage.setItem("selectedUser", JSON.stringify(record));
    if(roles.indexOf('ROLE_ADMIN_OWNER')!==-1 ||roles.indexOf('ROLE_ADMIN_APP') !==-1 ||roles.indexOf('ROLE_ADMIN_INITIATOR') !==-1||roles.indexOf('ROLE_ADMIN_SUPER') !==-1){

    if (item === "Manage Transaction") setShowUserAction(true);
    if (item === "Manage Kyc") navigate(`/users_kyc/${id}`);
    if (item === "Deactivate User" || item === "Activate User") handleDactivateUser(id);
    if (item === "Manage Profile") handleOpenProfile(id);
    if (item === "Login Tracker") handleOpenLoginTracker(id);
    if (item === "Manage Team Members")navigate(`/users/manage_team/${id}`);
    if (item === "Verify") verify(record?.email??record?.phoneNumber);
    //  NotificationManager.warning("Coming Soon !");
    if (item === "Activity Log") navigate(`/user/activity_log/${id}`);
    if (item === "Delete User") handleDeleteUser(id);
    if (item === "Reset Credentials") handleResetCredentials(record);
    if (item === "Block User" || item === "Unblock User" 
    || item ==="Unlock Transaction" || item ==="Lock Transaction") handleRToggleLock(id, record);

    }else{
      NotificationManager.error('Not authorized');
    }
  };

  const handleOpenLoginTracker = (id) => {
    navigate(`/user/login_tracker/${id}`);
  };
  const handleOpenProfile = (id) => {
    const item = users.find((user) => user.userId === id);
    localStorage.setItem("user_profile", JSON.stringify(item));
    navigate(`/users/profile/${id}`);
  };

  var searchInput = useRef({});
  const columns = [
    {
      title: "SURNAME",
      dataIndex: "surname",
      sorter: (a, b) => a.surname - b.surname,
      width: "10%",
      ...getColumnSearchProps(
        "surname",
        setSearchData,
        searchData,
        searchInput
      ),
    },
    {
      title: "FIRSTNAME",
      dataIndex: "firstName",
      sorter: (a, b) => a.firstName - b.firstName,
      width: "10%",
      ...getColumnSearchProps(
        "firstName",
        setSearchData,
        searchData,
        searchInput
      ),
    },
    {
      title: "PHONE NUMBER",
      sorter: (a, b) => a.phoneNumber - b.phoneNumber,
      dataIndex: "phoneNumber",
      ...getColumnSearchProps(
        "phoneNumber",
        setSearchData,
        searchData,
        searchInput
      ),
    },
    {
      title: "EMAIL",
      sorter: (a, b) => a.email - b.email,
      dataIndex: "email",
      width: "10%",
      ...getColumnSearchProps("email", setSearchData, searchData, searchInput),
    },
    // {
    //   title: "CITY",
    //   dataIndex: "city",
    //   sorter: (a, b) => a.city - b.city,
    //   ...getColumnSearchProps("city",setSearchData,searchData,searchInput),
    // },
    // {
    //   title: "DISTRICT",
    //   dataIndex: "district",
    //   sorter: (a, b) => a.district - b.district,
    //   ...getColumnSearchProps("district",setSearchData,searchData,searchInput),
    // },
    {
      title: "USER TYPE",
      dataIndex: "corporate",
      sorter: (a, b) => a.corporate - b.corporate,
      render: (usertype) => `${usertype === true ? "Business" : "Personal"}`,
    },
    {
      title: "STATUS",
      dataIndex: "active",
      sorter: (a, b) => a.active - b.active,
      render: (active) => {
        return active === true ? (
          <Badge status="success" text="Active" />
        ) : (
          <Badge status="error" text="Inactive" />
        );
      },
    },
    {
      title: "Account Locked",
      dataIndex: "accountNonLocked",
      render: (active) => {
        return active === true ? (
          "False"
        ) : (
          "True"
        );
      },
    },
    {
      title: "Account Delete",
      dataIndex: "deleted",
      render: (active) => {
        return active === true ? (
          "True"
        ) : (
          "False"
        );
      },
    },
    {
      title: "Business ID",
      dataIndex: "loginId",
    },
    {
      title: "Account Expired",
      dataIndex: "accountNonExpired",
      render: (active) => {
        return active === true ? (
          "False"
        ) : (
          "True"
        );
      },
    },
    {
      title: "DATE CREATED",
      dataIndex: "createdAt",
      render: (createdAt) => createdAt,
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
    {
      title: "ACTION",
      dataIndex: "id",
      render: (id, record) => (
        <AntDropdown
          dropDownOptions={[
            "Manage Profile",
            "Manage Team Members",
            "Manage Transaction",
            "Manage Kyc",
            "Login Tracker",
            "Activity Log",
            "Reset Credentials",
            record?.active?"":'Verify',
            record?.active===true?"Deactivate User":"Activate User",
            record?.accountNonLocked?"Block User":"unblock User",
            record?.accountNonLocked?"Lock Transaction":"Unlock Transaction",
           
          ]}
          handleDropdownAction={(item) =>
            handleDropdownAction(item, id, record)
          }
          userId={id}
          isArrow
        />
      ),
    },
  ];

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const handleOpenDeactivateUsers = () => {
    if(roles.indexOf('ROLE_ADMIN_OWNER') !==-1 ||roles.indexOf('ROLE_ADMIN_SUPER') !==-1 ||roles.indexOf('ROLE_ADMIN_INITIATOR') !==-1) {
    setShowDeactivateUser(true);
    }else{
      return NotificationManager.error('not authrize!')
    }
  };
  const handleDactivateUser = async (userId) => {
    showLoader();
   /* const res = await httpPost(
      `/api/v1/admin/users/activation-toggle?userId=${userId}`,
      {},
      customBaseUrl.authUrl
    );
    */

    let d = new Date();
    const res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
    {
      payload: JSON.stringify({userId}),
      "requestId": `REQ${d.getTime()}`,
      "requestType": "ACTIVATIONTOGGLE",
      customerName: selectedRecord?.firstName+' '+selectedRecord?.surname
    } )

    hideLoader();
    if (res.status === true) {
      setShowDeactivateUser(false);
      Swal.fire(res.message, "success");
      // handleGetUsers({ pagination });
    } else {
      Swal.fire(res.message, "error");
    }
  };

  function clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === '') {
        delete obj[propName];
      }
    }
    return obj
  }

  const getUsers = async (e) => {
    e.preventDefault();
    showLoader();
    const response = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/admin/users-list?fromDate=${moment(filteredDate?.fromdate).format('YYYY-MM-DD')}&toDate=${moment(filteredDate?.todate).format('YYYY-MM-DD')}`
    );
    
    const filter = clean(filteredData);

    if (response.status === true) {
      console.log('filter', filteredData);
      const filtered = response?.data?.filter(e=>  {
        //  console.log(filteredData)
        return Object.keys(filter).every(key => {
          return filter[key] === e[key];
        });
          //if( e?.tranCategory?.includes(filteredData?.tranCategory) && e?.partTranType?.includes(filteredData?.partTranType) ) return e
        
      });
      console.log('filtered', filtered)
      setUsers(filtered);
      //setFiltered(response.data.content)
      //setMetaData(filtered?.length);
      //  console.log('filter', fil)
      
      hideLoader();
    } else {
      NotificationManager.error(response?.message)
      setUsers([]);
  
      hideLoader();
    }
  };


  return (
    <Layout>
      <div style={tool}>
        <Button
         size="medium"
         style={{ background: !showProfiles ? "#FF4B01" : "", fontSize: "10px", color: !showProfiles ? "#fff": "#000" }}
        onClick={()=>setShowProfile(false)}
        >
          All Users
        </Button>
        <Button
         size="medium"
         style={{ background: showProfiles ? "#FF4B01" : "", fontSize: "10px", color:  showProfiles ? "#fff": "#000" }}
          onClick={()=>setShowProfile(true)}
        >
          Users Profiles
        </Button>

        {/*<select
          className={styles.selectType}
          defaultValue="disabled"
          onChange={(event) => {
            setSearch({ ...search, searchBy: event.target.value });
          }}
        >
          <option value="disabled" disabled>
            Search By...
          </option>
          {columns
            .filter((x) => x.title !== "ACTION")
            .map((header) => (
              <option value={header.dataIndex}>
                {transformName(header.title)}
              </option>
            ))}
        </select>
        <div className={styles.searchRow}>
          <Input
            className={styles.searchInput}
            placeholder="Search Users by Name, Email or Date"
            onChange={(event) => {
              setSearch({ ...search, searchText: event.target.value });
            }}
            prefix={<SearchOutlined className={styles.searchIcon} />}
          />
        </div>*/}
        <div/>
        <div/>
        
        <Row type="flex" justify="end" align="middle">
          <Button
            size="medium"
            style={{ background: "black", fontSize: "10px", color: "#fff" }}
            onClick={handleOpenDeactivateUsers}
          >
            Deactivate Users
          </Button>
          <AntDropdown
            dropDownOptions={inviteUserOptions}
            handleDropdownAction={handleInviteUsersOption}
          >
            <Button
              size="medium"
              style={{ background: "#0086E8", fontSize: "10px", color: "#fff" }}
            >
              Invite Users
            </Button>
          </AntDropdown>
          <Button
              size="medium"
              style={{ background: "#FF4B01", fontSize: "10px", color: "#fff" }}
              onClick={()=>setShowExport(true)}
            >
              Export
            </Button>
          <AntDropdown
            size="medium"
            dropDownOptions={downloadTemplateOptions}
            handleDropdownAction={handleDownloadTemplateDropdownAction}
          >
            <Button
              style={{ fontSize: "10px", background: "#27AE60", color: "#fff" }}
            >
              {" "}
              Download Template
            </Button>
          </AntDropdown>
          <AntDropdown
            dropDownOptions={createUserOptions}
            handleDropdownAction={handleCreateUserDropdownAction}
          >
            <Button
              size="medium"
              style={{ background: "#FF4B01", fontSize: "10px", color: "#fff" }}
            >
              <PlusOutlined />
              Create New User
            </Button>
          </AntDropdown>
          <Button
            hidden={true}
            size="medium"
            style={{ background: "#4A4AFF", fontSize: "10px", color: "#fff" }}
          >
            <PlusOutlined />
            Submit KYC
          </Button>
        </Row>
      </div>
      {showProfiles ? (
      <UserProfiles/>
      ) : (
      <>
      <Card style={{ background: "transparent" }}>
        <Card.Grid style={gridStyle} className="mr-2">
          <p>Total Registered Users</p>
          <h2 className="text-left">{totalRegisteredUsers}</h2>
        </Card.Grid>
        <Card.Grid hoverable={false} style={gridStyle} className="mx-1">
          <p>Total Business Users</p>
          <h2 className="text-left">{totalCorporateUsers}</h2>
        </Card.Grid>
        <Card.Grid style={gridStyle} className="mx-1">
          <p>Total Personal Users</p>
          <h2 className="text-left">{totalIndividualUsers}</h2>
        </Card.Grid>
        <Card.Grid style={gridStyle} className="mx-1">
          <p>Total Active users</p>
          <h2 className="text-left">{totalActiveUsers}</h2>
        </Card.Grid>
        <Card.Grid style={gridStyle} className="ml-1">
          <p>Total Inactive Users</p>
          <h2 className="text-left">{totalInactiveUsers}</h2>
        </Card.Grid>
      </Card>

      <Grid
        style={{ marginTop: "10px" }}
        container
        spacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 6 }}
      ></Grid>
            <div>
        <MatButton 
        variant="contained" 
        style={{width: '100%', marginBottom: 10, backgroundColor: "#ff6700"}}
        onClick={()=>setSearchField(!searchField)}>Click to {searchField ? 'hide': 'show' } search field</MatButton>
      </div>
      <div hidden={!searchField} style={{background: "#ffffff", padding: 10}}>
        <form onSubmit={handleGetUsers}>
      <div class="row">
      <div class="input-group mb-3 col">
        <span class="input-group-text" id="basic-addon3">User Type</span>
        <select class="form-select" id="inputGroupSelect01"
          onChange={(e)=>setFilteredData({...filteredData, corporate: e?.target?.value === 'true' ?true : e?.target?.value === 'false' ? false: null})}
        >
          <option selected>Choose...</option>
          <option value={''}>ALL</option>
          <option value={true}> BUSINESS</option>
          <option value={false}> PERSONAL</option>

        
        </select>
      </div>
      <div className="input-group mb-3 col">
          <span className="input-group-text">Admin</span>
          <select class="form-select" id="inputGroupSelect02"
              onChange={(e)=>setFilteredData({...filteredData, admin: e?.target?.value === 'true' ?true : e?.target?.value === 'false' ? false: null})}
          >
          <option selected>Choose...</option>
          <option value={''}>ALL</option>
          <option value={true} >TRUE</option>
          <option value={false}>FALSE</option>         
        </select>        
      </div>
      <div class="input-group mb-3 col">
        <span class="input-group-text" id="basic-addon3">Users</span>
        <select class="form-select" id="inputGroupSelect01"
          onChange={(e)=>setIsSmUsers(e?.target?.value === 'true' ?true : false)}
        >
          <option selected>Choose...</option>
          <option value={false}>ALL</option>
          <option value={true}> SMU</option>        
        </select>
      </div>
       
        {/*<div class="col">
          <input type="text" class="form-control" placeholder="Last name" aria-label="Last name"/>
        </div>*/}
      </div>
      <div className="row">
        
        <div class="input-group mb-3 col">
         <span class="input-group-text" id="basic-addon3">First Name</span>        
          <input type="text" aria-label="Ref" class="form-control"
            onChange={(e)=>setFilteredData({...filteredData, firstName: e?.target?.value})}

          />
        </div>
        <div class="input-group mb-3 col">
          <span class="input-group-text" id="basic-addon3">Last Name</span>        
          <input type="text" class="form-control"  aria-label="tranId"
            onChange={(e)=>setFilteredData({...filteredData, surname: e?.target?.value})}

          />
        </div>
      </div>
      <div className="row">
      
      <div class="input-group mb-3 col">
       <span class="input-group-text" id="basic-addon3">Phone Number</span>        
        <input type="text" aria-label="First name" class="form-control"
          onChange={(e)=>setFilteredData({...filteredData, phoneNumber: e?.target?.value})}
        />
      </div>
      <div class="input-group mb-3 col">
        <span class="input-group-text" id="basic-addon3">Email</span>        
        <input type="email" class="form-control"  aria-label="Email"
           onChange={(e)=>setFilteredData({...filteredData, email: e?.target?.value})}
        />
      </div>
    </div>
      <div class="row">
      <div class="input-group mb-3 col">
        <span class="input-group-text" id="basic-addon3">Active</span>
        <select class="form-select" id="inputGroupSelect01"
          onChange={(e)=>setFilteredData({...filteredData, active: e?.target?.value === 'true' ?true : e?.target?.value === 'false' ? false: null})}
        >
          <option selected>Choose...</option>
          <option value={''}>ALL</option>
          <option value={true}> TRUE</option>
          <option value={false}> FALSE</option>

        
        </select>
      </div>
      <div className="input-group mb-3 col">
          <span className="input-group-text">Account Locked</span>
          <select class="form-select" id="inputGroupSelect02"
              onChange={(e)=>setFilteredData({...filteredData, deleted: e?.target?.value === 'true' ?true : e?.target?.value === 'false' ? false: null})}
          >
          <option selected>Choose...</option>
          <option value={''}>ALL</option>
          <option value={true} >TRUE</option>
          <option value={false}>FALSE</option>         
        </select>        
      </div>
       
        {/*<div class="col">
          <input type="text" class="form-control" placeholder="Last name" aria-label="Last name"/>
        </div>*/}
      </div>
      
      <div class="row">
      <div class="input-group mb-3 col">
        <span class="input-group-text" id="basic-addon3">Account Expired</span>
        <select class="form-select" id="inputGroupSelect01"
          onChange={(e)=>setFilteredData({...filteredData, accountNonExpired: e?.target?.value === 'true' ? true : e?.target?.value === 'false' ? false: null})}
        >
          <option selected>Choose...</option>
          <option value={''}>ALL</option>
          <option value={true}> TRUE</option>
          <option value={false}> FALSE</option>

        
        </select>
      </div>
      <div className="input-group mb-3 col">
          <span className="input-group-text">Account Deleted</span>
          <select class="form-select" id="inputGroupSelect02"
              onChange={(e)=>setFilteredData({...filteredData, deleted: e?.target?.value === 'true' ?true : e?.target?.value === 'false' ? false: null})}
          >
          <option selected>Choose...</option>
          <option value={''}>ALL</option>
          <option value={true} >TRUE</option>
          <option value={false}>FALSE</option>         
        </select>        
      </div>
       
        {/*<div class="col">
          <input type="text" class="form-control" placeholder="Last name" aria-label="Last name"/>
        </div>*/}
      </div>
     
      <div className="row">
      
        <div class="input-group mb-3 col">
         <span class="input-group-text" id="basic-addon3">From</span>        
          <input type="date" aria-label="Date" class="form-control"
            onChange={(e)=>setFilteredDate({...filteredDate, fromdate: e?.target?.value})}
            required
          />
        </div>
        <div class="input-group mb-3 col">
          <span class="input-group-text" id="basic-addon3">To</span>        
          <input required type="date" class="form-control" placeholder="Date From" aria-label="Date from"
             onChange={(e)=>setFilteredDate({...filteredDate, todate: e?.target?.value})}
          />
        </div>
      </div>
      <div class="col-12 text-center">
        <button type="submit" class="btn btn-primary"
        >Search</button>
      </div>
      </form>
      </div>
      <div >
        <div class="col-md-12">
          <MatButton
            variant="contained" 
            style={{marginRight:20}}
            // onClick={()=>setShowStatement(true)}
          >
            <ExportOutlined/>         
            <CSVLink
          data={users}
          style={{            
            color: "#fff",
            display: "flex",
            alignItems: "center",
            textDecoration:"none"
          }}
          filename={`users.csv`}
          onClick={(event, done) => {
             //event.preventDefault();
              // handleFilterOperation();
            //done()           
          }}
        >
          DOWNLOAD AS EXCEL
        </CSVLink>
          </MatButton> 
          <MatButton
            //className="update-button-1"
            onClick={() => generatePDF(users, filteredDate)}
            variant="contained" 
            color="success"
          >
            <ExportOutlined/> 
            <div>DOWNLOAD AS PDF</div>
          </MatButton>           
          
      </div>
      </div>

      {users.length ? (
        <div className="mt-3">
          <AntTable
            pagination={pagination}
            users={users}
            loading={loading}
            handleGetNext={handleGetUsers}
            columns={columns}
          />
        </div>
      ) : (
        <>
          <Row
            type="flex"
            justify="center"
            align="middle"
            className="mt-5"
            style={{ minHeight: "40vh" }}
          >
            <img src={noDataLogo} alt="nodata" style={{ height: "300px" }} />
          </Row>
          <h4 className="mt-3 text-center">No User yet</h4>
          <p className="mt-3 text-center" style={{ color: color.textGray }}>
            Your users will appear here when you onboard them
          </p>
        </>
      )}

      {showDeactivateUser ? (
        <DeactivateUsers
          showModal={showDeactivateUser}
          hideModal={setShowDeactivateUser}
          title="Deactivate Single User"
          className={styles.modal}
          handleDactivateUser={handleDactivateUser}
          users={users}
        />
      ) : (
        ""
      )}
      {showCreateUser ? (
        <CreateSingleUserModal
          showModal={showCreateUser}
          hideModal={setShowCreateUser}
          title="Single User"
          className={styles.modal}
        />
      ) : (
        ""
      )}
      {showCreateUserExcel ? (
        <CreateBulkUser
          showModal={showCreateUserExcel}
          hideModal={setShowCreateUserExcel}
          title="Upload Excel Sheet to Create Users"
          className={styles.modal}
        />
      ) : (
        ""
      )}
      {showCreateUserForms ? (
        <CreateBulkForm
          showModal={showCreateUserForms}
          hideModal={setShowCreateUserForms}
          title="Create Single or Bulk Users Online"
          className={styles.modal}
        />
      ) : (
        ""
      )}
      {showUserAction ? (
        <UserActionsModal
          showModal={showUserAction}
          hideModal={setShowUserAction}
          className={styles.modal}
          userId={selectedId}
          record = {selectedRecord}
        />
      ) : (
        ""
      )}

{showEmail  ? (
        <EmailInvite
          showModal={showEmail}
          hideModal={setShowEmail}
          className={styles.modal} 
          type='email'       
        />
      ) : (
        ""
      )}

{showBulkSms  ? (
        <EmailInvite
          showModal={showBulkSms}
          hideModal={setShowBulkSms}
          className={styles.modal} 
          type='sms'       
        />
      ) : (
        ""
      )}
      {showSocial  ? (
        <SocialInvites
          showModal={showSocial}
          hideModal={setShowSocial}
          className={styles.modal} 
        />
      ) : (
        ""
      )}

      {showExport? (
        <ExportCSV
          showModal={showExport}
          hideModal={setShowExport}
          dataList={users}
          />) : (
            ""
          )   
      }
      {showVerify && (
        <VerifyUser
        showModal={VerifyUser}
        hideModal={setShowVerify}        
    title = 'kkk'
    description='kkk'
    buttonLabel='kkkj'
    handleSubmit='kjs'
    loading='ooo'
        />     
      ) 
      }
 {showVerifyAccount ? (
        <PinModal
          center
          showModal={showVerifyAccount}
          hideModal={setShowVerifyAccount}
          otp={otp}
          setOtp={setOtp}
          separator=""
          title="Verify Your Account"
          description={`Please input the OTP sent to ${phone}`}
          //isResendOtp
          buttonLabel="Verify"
          handleSubmit={completeVerification}
          loading={loading}
          numInputs={6}
          //resendOtp={setShowResendOtp}
        />
      ) : (
        ""
      )}
    </>)}
    </Layout>
  );
};

const gridStyle = {
  width: "19%",
  textAlign: "left",
  backgroundColor: "#fff",
  padding: "24px 10px 24px 12px",
  fontWeight: "bold",
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-between",
  marginBottom: "30px",
};

export default Index;
