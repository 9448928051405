import './qrIndex.css';
import { useState, useEffect } from 'react';
import {
  FaSortDown,
  FaPlus,
  FaExternalLinkAlt,
  FaSistrix,
} from 'react-icons/fa';
import Layout from '../../components/Layout';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import { customBaseUrl, httpDelete, httpGet, httpPost, httpPostFormData } from '../../store/http';
import GeneratedQr from './generatedQrModal';
import GenerateQr from './generateQr';
import AntDropdown from '../../components/CustomDropdown/AntDropdown';
import ManageMarketer from './manageMarketer';
import ExcelUpload from './excelUpload';
import { Col, Row } from 'antd';
import ExportQr from './exportQr';
import GenQR from './genQR';
import ManageQR from './manageQr';
import ExportCSV from './csvExport';
import { hideLoader, showLoader } from '../../utils/loader';
import { error, success } from '../../services/swal-mixin';
import Swal from 'sweetalert2';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';


const Date = () => {
  const [active, setActive] = useState(false);
  return (
    <div className="dropdown date-dropdown">
      <div
        className="dropdown-btn date-dropdown-btn"
        onClick={(e) => setActive(!active)}
      >
        {' '}
        Date
        <p className="arrow-down">
          <FaSortDown />
        </p>
      </div>
      {active && (
        <div className="dropdown-content date-dropdown-content">
          <div className="dropdown-item date-dropdown-item">
            <h6 className="start-date">Start Date</h6> 
            <input type="date" />
          </div>
          <div className="dropdown-item date-dropdown-item">
            <h6 className="end-date">End State</h6>
            <input type="date" />
          </div>
        </div>
      )}
    </div>
  );
};

const Search = ({ selected,     setOpenExport,  setOpenExcel, setSelected, setSearchText, searchText }) => {
  const [openGenerateModal, setOpenGenerateModal] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const options = ['Id', 'Email', 'Name'];

  const handleGenerateModal = () => {
    setOpenGenerateModal(true);
    // setQrData(qrValue);
  };

  const dropDownOptions = ["Create single user", "Upload excel sheet to create"];
  const handleDropdownAction = (item, row) => {
    if (item === "Create single user")  handleGenerateModal();  
    if (item === "Upload excel sheet to create") setOpenExcel(true)  

  };

  

  return (
    <section>
      <div className="search-wrapper">
        <div className="container">
          <div className="dropdown">
            <div
              className="dropdown-btn"
              onClick={(e) => setIsActive(!isActive)}
            >
              {selected}
              <p className="arrow-down">
                <FaSortDown />
              </p>
            </div>

            {isActive && (
              <div className="dropdown-content">
                {options.map((option) => (
                  <div
                    onClick={(e) => {
                      setSelected(option);
                      setIsActive(false);
                    }}
                    className="dropdown-item"
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>

          <SearchAndFilter searchText={searchText} setSearchText={setSearchText} />
          <div className="mechanics">
            <div className="mechanics-icon" onClick={()=>setOpenExport(true)}>
              <div className="icon">
                <FaExternalLinkAlt />
              </div>
              <div className="export-data">Export CSV</div>
            </div>
            <AntDropdown handleDropdownAction={handleDropdownAction} dropDownOptions={dropDownOptions}>
            <div style={{ cursor: 'pointer'}} className="mechanics-icon">
              <div className="icon">
                <FaPlus />
              </div>
              <div className="generate-code"                
              >
                Create Field Marketer
              </div>
            </div>
            </AntDropdown>
          </div>
        </div>
      </div>
      {openGenerateModal && (
        <GenerateQr
          modalState={openGenerateModal}
          modalCloseAction={() => setOpenGenerateModal(false)}
        />
      )}
    </section>
  );
};

const SearchAndFilter = ({setSearchText, searchText}) => {
  return (
    <div className="search">
      <div className="searchInputs">
        <div className="searchIcon">
          <FaSistrix />
        </div>
        <input
          className="text"
          type="text"
          placeholder="Search users by Id, email or name"
          value={searchText}
          onChange={(e)=>setSearchText(e.target.value)}
        />
      </div>
      <div className="dataResult"></div>
    </div>
  );
};

const Status = ({ selected, setSelected }) => {
  const [isActive, setIsActive] = useState(false);
  const options = ['Active', 'Expired'];
  return (
    <div className="status-container">
      <div className="status">
        <div className="dropdown status-dropdown">
          <div
            className="dropdown-btn status-dropdown-btn"
            onClick={(e) => setIsActive(!isActive)}
          >
            Status
            <p className="arrow-down">
              <FaSortDown />
            </p>
          </div>

          {isActive && (
            <div className="dropdown-content status-dropdown-content">
              {options.map((option) => (
                <div
                  onClick={(e) => {
                    setSelected(option);
                    setIsActive(false);
                  }}
                  className="dropdown-item status-dropdown-item"
                >
                  {option}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="date-container">
        <div>
          <Date />
        </div>
      </div>
    </div>
  );
};

const QR = () => {
  const [selected, setSelected] = useState('Search by.....');
  const [state, setState] = useState([]);
  const [assigned, setAssigned] = useState(0);
  const [unassigned, setUnassigned] = useState(0);
  const [qrData, setQrData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [opengenQr, setOpenGenQr] = useState(false);
  const [showManageMarkert, setShowManageMarkert] = useState(false);
  const [showManageQR, setShowManageQR] = useState(false);
  const [openExcel, setOpenExcel] = useState(false)
  const [openExport, setOpenExport] = useState(false)
  const [searchText, setSearchText] = useState("");
  const [totalQRGen, setTotalQRGen] = useState(0);
  const [totalExported, setTotalExported] = useState(0);
  const [count, setCount] = useState('')
  const [selectedMarketer, setSelectedMarketer] = useState([]);
  const [totalActive, setTotalActive] = useState(0);
  const [totalInactive, setTotalInactive] = useState(0);
  const [marketersStat, setMarketersStat] = useState([]);
  const [marketers, setMarketers] = useState([]);
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 20,
  });
  const [stat, setStat] = useState([]);


  const renderDetailsButton = (params) => {
    const handleModal = (qrValue) => {
      setOpenModal(true);
      setQrData(qrValue);
    };
    return (
      <strong>
        <Button
          key={params?.row?.action?.id}
          variant="contained"
          size="small"
          style={{ 
            width: '120px',
            backgroundColor: '#FFF1EC',
            border: '1px solid #FF4B01',
            color: '#FF4B01',
          }}
          // onClick={() => setOpenModal(true)}
          onClick={() => {
            handleModal(params?.row?.action?.qrCode)
            // setQrData(params?.row?.action?.qrCode)
          }}
        >
          View QR Code
        </Button>
      </strong>
    );
  };
  // console.log(qrData);
  const deActivateUser = async (userId) => {
    showLoader();
    const response = await httpPostFormData(
      `${customBaseUrl.qrCodeUrl}/api/v1/log/toggle/${userId}`,
      {},
      
    );
    hideLoader();
    const { status, message } = response.data;
    if (status === false) {
      hideLoader()
      return Swal.fire("error",response?.message);
    }
    hideLoader()
    Swal.fire(message);
  };
  const handleChangeRowsPerPage = async (value) => {
    console.log(value);
    setPagination({ ...pagination, current: 0, pageSize: value });
    //await getTransactions(0, value);
  };

  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
   // await getTransactions(newPage, pagination.pageSize);
  };

  const handleDeleteUser = async (id) => {
  
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.value) {
          showLoader();
          const res = await httpDelete(
            `${customBaseUrl.authUrl}/api/v1/user/delete/${id}`
          );
          if (res.status === true) {
            hideLoader();
            NotificationManager.success("User Deleted Successfully");
          }
          else{
            hideLoader();
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.message,
            })
          }
        }
      });
    };
  const dropDownMarketerOpt =(e)=> ["View users onboard", "Delete User", e?.active?"Deactivate User": "Activate User"];
  const handleMarketerAction = (item, row) => {
    setSelectedMarketer(row);
    if (item === "View users onboard")   setShowManageMarkert(true)
    if (item === "Deactivate User" || item === "Activate User")   deActivateUser(row.userId)
    if (item === "Delete User")   handleDeleteUser(row.userId);



  };

  const columns = [
    {
      field: 'name',
      headerName: 'NAME',
      headerClassName: 'super-app-theme--header',
      width: 200,
      // renderCell: ({row}) => `${row?.firstName} ${row?.lastName }`
    },
    {
      field: 'phoneNumber',
      headerName: 'PHONE',
      headerClassName: 'super-app-theme--header',
      width: 200,
    },
   
    // {
    //   field: 'email',
    //   headerName: 'EMAIL',
    //   headerClassName: 'super-app-theme--header',
    //   width: 250,
    // },
    {
      field: 'numberOfCardsAssigned',
      headerName: 'CARDS ASSIGNED',
      headerClassName: 'super-app-theme--header',
      width: 150,
    },
    {
      field: 'numberOfQRCodeAssigned',
      headerName: 'QR ASSIGNED',
      headerClassName: 'super-app-theme--header',
      width: 150,
    },
    /*{
      field: 'userType',
      headerName: 'QR EXPORTED',
      headerClassName: 'super-app-theme--header',
      width: 150,
    },
    {
      field: 'description',
      headerName: 'QR ASSIGNED',
      headerClassName: 'super-app-theme--header',
      width: 150,
    },
    {
      field: 'accountNo2',
      headerName: 'QR UNASSIGNED',
      headerClassName: 'super-app-theme--header',
      width: 150,
    },*/
    {
      field: 'isActive',
      headerName: 'USER STATUS',
      headerClassName: 'super-app-theme--header',
      width: 150,
      renderCell: ({row})=>
      <span
      style={{
        color: `${row.isActive ? "#27AE60" : "#EB5757"}`,
      }}
    >
      {row.isActive?'Active':'Inactive'}
    </span>    },
    {
      field: 'date',
      headerName: 'DATE',
      headerClassName: 'super-app-theme--header',
      width: 200,
      renderCell: ({row}) =>moment(row?.date).format('DD/MM/YYYY')
    },
    {
      field: 'action',
      headerName: 'ACTION',
      headerClassName: 'super-app-theme--header',
      width: 160,
      renderCell: ({ row }) => (
        <AntDropdown
          dropDownOptions={["View users onboard", "Delete User", row?.active?"Deactivate User": "Activate User"]}
          handleDropdownAction={(item) => handleMarketerAction(item, row)}
          userId={row}
          isArrow
        />
      ),
    },
  ];

  const getStat = async () => {
    showLoader();

     const res = await httpGet(
      `${customBaseUrl.cardUrl}/api/cards/summary`
    );
    if(res?.status === true){
      hideLoader();
      const data = res;
      setStat(res?.data) 
     }else{
      hideLoader();
  
     }  
    hideLoader();
  };
  useEffect(() => {
  
    getStat();
  }, []);


  useEffect(() => {
    async function fetchData() {
      const response = await httpGet(
        `${customBaseUrl.authUrl}/api/v1/marketers`
      );
      
      const responseSum = await httpGet(
        `${customBaseUrl.authUrl}/api/v1/marketers/summary`
      );
      if (response?.status === true) {
      setMarketers(response.data)
      }
      if (responseSum?.status === true) {
        setMarketersStat(responseSum?.data);
      }

      const responseTG = await httpGet(
        `${customBaseUrl.qrCodeUrl}/api/v1/qrcode/get-total-count`
      );
      if( responseTG?.status !=401 && responseTG?.status != false){        
      setTotalQRGen(responseTG);
      }
      const assignedQr = await httpGet(
        `${customBaseUrl.qrCodeUrl}/api/v1/qrcode/get-assigned-count`
      );
      if( assignedQr?.status !=401 && assignedQr?.status != false){        
      setAssigned(assignedQr);
      }
      const unassignedQr = await httpGet(
        `${customBaseUrl.qrCodeUrl}/api/v1/qrcode/get-unassigned-count`
      );
      if( unassignedQr?.status !=401 && unassignedQr?.status != false){        
      setUnassigned(unassignedQr);
      }
      const exported = await httpGet(
        `${customBaseUrl.qrCodeUrl}/api/v1/qrcode/get-total-exported`
      );
      if( exported?.status !=401 && exported?.status != false){        
        setTotalExported(exported);
        }
        const active = await httpGet(
          `${customBaseUrl.qrCodeUrl}/api/v1/log/total-active`
        );
        if( exported?.status !=401 && exported?.status != false){        
          setTotalActive(active);
          }
          const inActive = await httpGet(
            `${customBaseUrl.qrCodeUrl}/api/v1/log/total-inactive`
          );
          if( exported?.status !=401 && exported?.status != false){        
            setTotalInactive(inActive);
            }
      //setAssigned(assignedQr);
      //setUnassigned(unassignedQr); /api/v1/log/total-active
      setState(response);
      
    }
    fetchData();
  }, []);

  console.log('state', state);


  const modalCloseAction = () => {
    setOpenModal(false);
    setOpenGenQr(false);
  }

  const handleManageMarketers = () => {
    setShowManageMarkert(true)
  }

    /*
  const rows = state?.map((row, index) => ({
    id: index,
    userFullName: row.userFullName,
    qrKey: row.qrKey,
    age: row.status,
    phoneNo: row.phoneNumber,
    userType: row.userType,
    description: row.description,
    accountNo: row.accountNo,
    dateCreated: row.dateCreated,
    action: row,
  }));
  */
  return (
    <Layout>
      {!showManageQR ? 
      (!showManageMarkert?(<div className="App">
        <div>
          <Button
              size="medium"
              style={{ background: "#fff", fontSize: "10px", color: "#000" }}
              onClick={()=>setShowManageQR(true)}
            >
              Manage QR code
            </Button>
            <Button 
              onClick={()=>{setShowManageQR(false)}}
              size="medium"
              style={{ background: "#FF4B01", margin: "10px", fontSize: "10px", color: "#fff" }}
            >
              Manage Field Marketer 
            </Button>
        </div>
        <div>
        <Search  setOpenExport={setOpenExport} searchText={searchText}  setSearchText={setSearchText}   setShowManageMarkert={setShowManageMarkert} setOpenExcel={setOpenExcel} selected={selected} setSelected={setSelected} />
        <div className="cards-container row">
        <div className="cards col">
            <p>Total Field Marketers</p>
            <h2>{marketersStat?.totalMarketers ?? 0}</h2>
          </div>
          <div className="cards col-1">
            <p>Total Active and Inactive Field Marketers</p>
            <Row gutter={6} style={{padding:10}}>
             <Col span={10}>
                <div>Active </div>
                <h4>{marketersStat?.totalActiveMarketers ?? 0}</h4>                
              </Col>
              <Col className="gutter-row" span={8}>
               <div>Inactive</div>
               <h4>{marketersStat?.totalInactiveMarketers ?? 0}</h4>
              </Col>
            </Row>            
          </div>
          <div className="cards col">
            <p>Total Exported QR Codes</p>
            <h2>{totalExported}</h2>
          </div>
          <div className="cards col">
            <p>Total Assigned QR Codes</p>
            <h2>{assigned}</h2>
          </div>          
          <div className="cards col">
            <p>Total Unassigned QR Codes</p>
            <h2>{unassigned}</h2>
          </div>
          <div className="cards col">
            <p>Total Exported Cards</p>
            <h2>{stat?.totalExportedCards ?? 0}</h2>
          </div>
          <div className="cards col">
            <p>Total Assigned Cards</p>
            <h2>{stat?.totalAssignedCards ?? 0}</h2>
          </div>          
          <div className="cards col-sm-2">
            <p>Total Unassigned Cards</p>
            <h2>{stat?.totalUnassignedCards ?? 0}</h2>
          </div>
          </div>
        </div>
        {//<Status selected={selected} setSelected={setSelected} />
        }<Box
          sx={{
            width: 1,
            '& .super-app-theme--header': {
              background: '#ff6700',
              color: '#ffffff',
            },
          }}
        >
          <div
            style={{
              height: 650,
              width: '100%',
              background: '#fff',
              borderRadius: '8px',
            }}
          >
            <DataGrid
              getRowId={row=>row.marketerUserId}
              rows={
               // (selected==="Id" && searchText) ? state?.filter(e=>e?.userId==searchText):
                //(selected==="Name" && searchText)?state?.filter(e=>e?.firstName.includes(searchText) || e?.lastName.includes(searchText)):
                //(selected==="Email" && searchText)?state?.filter(e=>e?.email.includes(searchText))
                 //:State
                 marketers
                }
              columns={columns}
              rowHeight={125}
              page={pagination.current}
              //  paginationMode="server"
              pageSize={pagination.pageSize}
              onPageSizeChange={(newPageSize) =>
                handleChangeRowsPerPage(newPageSize)
              }
              onPageChange={(newPage) => handleChangePage(newPage)}
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
              pagination
              components={{ Toolbar: GridToolbar }}
            />
          </div>
        </Box>
      </div>):<ManageMarketer selectedMarketer={selectedMarketer} setShowManageMarkert={setShowManageMarkert}/>)  :
      <ManageQR setShowManageQR={setShowManageQR}/>}
      {openModal && <GeneratedQr modalState={openModal} modalCloseAction={modalCloseAction } qrValue={qrData}/>}
      {opengenQr && <GenQR showModal={opengenQr} hideModal={modalCloseAction } count={count} setCount={setCount}/>}

      {
        openExcel &&
        <ExcelUpload
          showModal={openExcel}
          hideModal={setOpenExcel}
        />
      }
      {
        openExport &&
        <ExportCSV
          showModal={openExport}
          hideModal={setOpenExport}
          dataList={state}
        />
      }
    </Layout>
  );
}

export default QR;


