import React from "react";
import styled from "styled-components";
import { Input, Button, Switch } from "antd";
import useStyles from "./styles";
import UserIcon from "../../../assets/icons/userOrange.svg";
import Check from "../../../assets/icons/check.svg";
import NotCheck from "../../../assets/icons/notCheck.svg";
import Layout from "../../../components/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeftOutlined, EditFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import styles from "./styles";
import { hideLoader, showLoader } from "../../../utils/loader";
import { customBaseUrl, httpGet, httpPut, httpPost } from "../../../store/http";
import { TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Swal from "sweetalert2";
import moment from "moment";
import { Edit } from "@mui/icons-material";
import { currencyFormat } from "../../../utils/helper";
import TransactionHistory from "../../../components/ManageWallet/Transaction History";
import UserTransactionHistory from "../../../components/ManageWallet/Transaction History/userTransactions";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
const EditWRapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ProfilePage = () => {
  const { id: id } = useParams();
  const navigate = useNavigate();
  const styles = useStyles();
  const [user, setUser] = useState({});
  const [storedUser, setStoredUser] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [userType, setUserType] = useState(false);
  const [email, setEmail] = useState("");
  const [referenceCode, setReferenceCode] = useState("");
  const [gender, setGender] = useState("");
  const [smsAlertConfig, setSmsAlertConfig] = useState(false);
  const [userId, setUserId] = useState("");
  const [virtualAccountNumber, setVirtualAccountNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [status, setStatus] = useState("");
  const [userModel, setUserModel] = useState({});
  const [bvnData, setBvnData] = useState([]);
  const [limitData, setLimitData] = useState([]);
  const [count, setCount] = useState(0);
  const [volume, setVolume] = useState(0);
  const [kyc, setKyc] = useState([])
  const [tranStatus, setTranStatus] = useState([])
  const [ userData, setUserData] = useState({});
  const [category, setCategory] = useState('general');
  const [businessType, setBusinessTypes] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [defaultWallet, setDefaultWallet] = useState('');
  const [products, SetProducts]= useState([]);
  const [cards, setCards] = useState([]);
  const [logs, setLogs] = useState([]);
  const [pagination, setPagination] = useState({ current: 0, pageSize: 10 });
  const [metaData, setMetaData] = useState(0);
  
  useEffect(() => {
    getBvn();
    getCards();
    getProducts();
    getTerminals();
    getAccounts();
    getHistory();
    getTransStatus();
    getUser();
    getLimit();
    getKYC();
    getBusinessTypes();
  }, []);
  const getBusinessTypes = async () =>{
    let response = await httpGet(`${customBaseUrl.authUrl}/api/v1/business/type/find/all?page=0&size=50`);
    // console.log(response);
    setBusinessTypes(response.businessTypeList);
  }
  const getUser = async () => {
    // showLoader();
    const response = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/profile/${id}`
    );
    hideLoader();
    // const storedUser = JSON.parse(localStorage.getItem("user_profile"));


    if (response.status !== true) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
        confirmButtonText: "Ok",
      });

    }
    else {
      const user = response.data;
      console.log('user',user);
      setUserData({
        ...userData,
        address: response?.data?.address,
  businessType: response?.data?.otherDetails?.businessType,
  city: response?.data?.city,
  dateOfBirth: response?.data?.dateOfBirth,
  email: response?.data?.email,
  firstName: response?.data?.firstName,
  gender: response?.data?.gender,
  middleName: response?.data?.middleName,
  officeAddress: response?.data?.otherDetails?.organizationAddress,
  organisationEmail: response?.data?.otherDetails?.organisationEmail,
  organisationName: response?.data?.otherDetails?.organisationName,
  organisationPhone: response?.data?.otherDetails?.organisationPhone,
  organisationType: response?.data?.otherDetails?.organisationType,
  organizationCity: response?.data?.otherDetails?.organizationCity,
  organizationState: response?.data?.otherDetails?.organizationState,
  phoneNumber: response?.data?.phoneNumber,
  profileId: response?.data?.id,
  state: response?.data?.otherDetails?.organizationState,
  surname: response?.data?.surname
      })
      setUser(user);
      setStoredUser(storedUser);
      setFirstName(user.firstName);
      setLastName(user.surname);
      setDateOfBirth(new Date(user.dateOfBirth));
      setUserType(user.corporate);
      setEmail(user.email);
      setReferenceCode(user.referenceCode);
      setGender(user.gender);
      setPhoneNumber(user.phoneNumber);
      setSmsAlertConfig(user.smsAlertConfig);
      setUserId(user.userId);
      setVirtualAccountNumber("32342424242");
      setStatus(storedUser.active);

      setUserModel({
        firstName: user.firstName,
        surname: user.surname,
        dateOfBirth: user.dateOfBirth,
        email: user.email,
        gender: user.gender,
        phoneNumber: user.phoneNumber,
        smsAlertConfig: user.smsAlertConfig,
      });


    }

  };

  const getBvn = async () => {
    //showLoader();
      const response = await httpPost(
        `${customBaseUrl.kycUrl}/api/v1/admin/kyc/requirementsData/${id}`
      );
    hideLoader();

    if (response.status) {
    setBvnData(response?.data)
    console.log('bvnData', response?.data?.tier2[0]);
    }
    else {
     console.log(response)
    }
  };
  const getCards = async () => {
    //showLoader();
      const response = await httpGet(
        `${customBaseUrl.cardUrl}/admin/list/card/${id}`
      );
    hideLoader();

    if (response?.status) {
    setCards(response?.data)
    }
    else {
     console.log(response)
    }
  };
  const getProducts = async () => {
    //showLoader();
      const response = await httpGet(
        `${customBaseUrl.walletUrl}/api/v1/pricing/products`
      );
    hideLoader();
      let pu=[]
    if (response?.status) {
      for (let i=0;i<response?.data?.length; i++){
        const response1 = await httpGet(
          `${customBaseUrl.walletUrl}/api/v1/pricing/user-products/${id}/${response.data[i].code}`
        );
        pu.push(response1.data)
      }
      SetProducts(pu);

      }
    else {
     console.log(response)
    }
  };
  const getTerminals = async () => {
    //showLoader();
      const response = await httpPost(
        `${customBaseUrl.terminalUrl}/api/v1/terminals/viewallterminalbyuser`,
        {
          from: "",
          pageNo: 0,
          pageSize: 20,
          paramList: [
            {
              id,
            }
          ],
          to: ""
        }
      );
    hideLoader();

    // if (response?.status) {
    // setCards(response?.data)
    // }
    // else {
    //  console.log(response)
    // }
  };
  
  const getAccounts = async () => {
    //showLoader();

      const response = await httpGet(
        `${customBaseUrl.walletUrl}/api/v1/wallet/admin/user/accounts/${id}`
      );
    hideLoader();

    if (response.status) {
      const defaultWal = response?.data.filter(e => e?.walletDefault === true)[0]
    setWallets(response?.data)
    setDefaultWallet(defaultWal?.accountNo)
    }
    else {
     console.log(response)
    }
  };
  const getHistory = async () => {
    //showLoader();

      const response = await httpGet(
        `${customBaseUrl.authUrl}/api/v1/history/user-history/${id}`
      );
    hideLoader();

    if (response?.status) {
      setLogs(response?.data);
      setMetaData(response?.data?.length)
    }
    else {
     console.log(response)
    }
  };
  const getLimit = async () => {
    //showLoader();
    const response = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/user/setup?id=${id}`
    );
    hideLoader();

    if (response.status === true) {
    setVolume(response?.data)
    }
    else {
     console.log(response)
    }
  };

  const getKYC = async () => {
    //showLoader();
    const response = await httpGet(
      `${customBaseUrl.kycUrl}/api/v1/kyc/fetchCurrentLevel/${id}`
    );
    hideLoader();

    if (response.status === true) {
    setKyc(response?.data)
    }
    else {
     console.log(response)
    }
  };
  const getTransStatus = async () => {
    //showLoader();
    const response = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/trans-type/status/${id}`
    );
    hideLoader();

    if (response.status === true) {
    setTranStatus(response?.data)
    }
    else {
     console.log(response)
    }
  };
  const handleChangePage = async (newPage) => {
    setPagination({ ...pagination, current: newPage });
    // await get(newPage, pagination.pageSize);
  };
  const handleChangeRowsPerPage = async (value) => {
    setPagination({ ...pagination, current: 0, pageSize: value });
    // await getTransactions(0, value);
  };

  const handleSmsAlertConfigChange = async (event) => {
    //setUserModel({ ...userModel, smsAlertConfig: event })
    let response = await httpPost(
      `${customBaseUrl.authUrl}/api/v1/admin/users/subscribe-sms-alert`,
      {
        phoneNumber: user.phoneNumber
      }
    );
    if (response.status === true) {
      setSmsAlertConfig(event);
      hideLoader();
      Swal.fire({
        title: "Success",
        text: response.message,
        icon: "success",
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    } else {
      hideLoader();
      Swal.fire({
        title: "Error",
        text: response.message,
        icon: "error",
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    }
  };
  const configTran = async (event) => {
    //setUserModel({ ...userModel, smsAlertConfig: event })
    let response = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/toggle/${id}?type=${event}`,
      {
        phoneNumber: user.phoneNumber
      }
    );
    if (response.status === true) {
      hideLoader();      
      Swal.fire({
        title: "Success",
        text: response.message,
        icon: "success",
        confirmButtonText: "OK",
        showCancelButton: false,
      });
      getTransStatus();
    } else {
      hideLoader();
      Swal.fire({
        title: "Error",
        text: response.message,
        icon: "error",
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    }
  };
  const handleSubmit =()=>{

  }
  const updateLimit = async () => {
  let res =  await httpPost(`${customBaseUrl.kycUrl}/kyc/admin/upgradeTransLimit/${id}?transactionLimit=${volume}`,{});
    if (res?.status) {
      console.log('success', res)
    }else{
      console.log('failed', res)
    }
  }
  const handleUpdateProfile = async () => {
    //return console.log('users', userData);
   showLoader();
    let isUserCorporate = userType === "Corporate" ? true : false;
    if (userType ) {
   /*   let response = await httpPut(
        `${customBaseUrl.authUrl}/api/v1/profile/update-corporate-profile/${id}`,
        userModel
      );*/
    let d= new Date()
    //let res =  await httpPost(`${customBaseUrl.kycUrl}/kyc/admin/upgradeTransLimit/${id}?transactionLimit=${volume}`,{});

    let response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
      {
        payload: JSON.stringify({...userData, userId:id}),
        "requestId": `REQ${d.getTime()}`,
        "requestType": "UPDATECORPORATEUSER",
        customerName: userData.firstName +' '+ userData.surname
      } )
      if (response.status === true) {
        hideLoader();
        updateLimit();
        Swal.fire({
          title: "Success",
          text: response.message,
          icon: "success",
          confirmButtonText: "OK",
          showCancelButton: false,
        });
      } else {
        hideLoader();
        Swal.fire({
          title: "Error",
          text: response.message,
          icon: "error",
          confirmButtonText: "OK",
          showCancelButton: false,
        });
      }
    } else {
     /* let response = await httpPut(
        `${customBaseUrl.authUrl}/api/v1/profile/update-personal-profile/${id}`,
        userModel
      );*/
      let d= new Date()
      let response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
      {
        payload: JSON.stringify({...userData, userId:id}),
        "requestId": `REQ${d.getTime()}`,
        "requestType": "UPDATEPERSONALUSER",
        customerName: userData.firstName +' '+ userData.surname
      } )
      if (response.status === true) {
        hideLoader();
        Swal.fire({
          title: "Success",
          text: response?.message,
          icon: "success",
          confirmButtonText: "OK",
          showCancelButton: false,
        });
      } else {
        hideLoader();
        Swal.fire({
          title: "Error",
          text: "Profile not updated",
          icon: "error",
          confirmButtonText: "OK",
          showCancelButton: false,
        });
      }
    }
  };
  const columns = React.useMemo(() => [
    {
      field: "country",
      headerName: "Country",
      width: 150,
      editable: false,
      headerClassName: styles.tableHeader,
    },
    {
      field: "device",
      headerName: "Device",
      width: 200,
      editable: false,
      sortable: true,
      headerClassName: styles.tableHeader,
    },
    {
      field: "ip",
      headerName: "IP Address",
      width: 150,
      editable: false,
      headerClassName: styles.tableHeader,
    },
    {
      field: "loginDate",
      headerName: "Login Date",
      sortable: true,
      width: 200,
      headerClassName: styles.tableHeader,
      renderCell: ({row})=> new Date(row?.loginDate).getDate()
    },
    
  ]);
  return (
    <Layout>
      <div style={{ cursor: "pointer" }} onClick={() => navigate("/users")}>
        <ArrowLeftOutlined
          style={{
            fontSize: "20px",
            fontWeight: "600",
            marginRight: "4px",
            marginTop: "10px",
          }}
        />
        <span className="me-1">back</span>
      </div>
      
      <EditWRapper>
        <div className={styles.topDiv}>
          <div className={styles.title}>User Profile</div>
          <div className="card py-3 px-1">
            <div className="card flex-row" style={{border: 0}}>
            <div className={styles.tDLTextTop}>{userData?.surname?.toUpperCase()} {userData?.firstName?.toUpperCase()}</div>
            <div className="flex-row px-1">
                <div>
                    <div>
                    User Account Number: <span style={{ fontWeight: 'bold'}} className="px-3 font-weight-bold">{defaultWallet?? ''}</span>
                    
                    Account Type: <span style={{ fontWeight: 'bold'}}>{user?.corporate ? 'Corporate' : 'Personal'}</span>
                    </div>
                </div>
            </div>
            </div>
            <hr />
            <div className="flex flex-row" style={{ fontSize: 12}}>
                <span
                    onClick={() => setCategory('general')}
                    style={{ marginRight: 4, color: category === 'general' ? '#ff6700' : '', cursor: 'pointer', border: 3, borderRadius: 5 }}>General</span>
                <span
                    onClick={() => setCategory('pricing')}
                    style={{ marginRight: 4, color: category === 'pricing' ? '#ff6700' : '', cursor: 'pointer' }}>List of Prouct & Pricing</span>
                <span
                    onClick={() => setCategory('document')}
                    style={{ marginRight: 4, color: category === 'document' ? '#ff6700' : '', cursor: 'pointer' }}>Document</span>
                <span
                    onClick={() => setCategory('bills')}
                    style={{ marginRight: 4, color: category === 'bills' ? '#ff6700' : '', cursor: 'pointer' }}>Bills Payment</span>
                <span
                    onClick={() => setCategory('logs')}
                    style={{ marginRight: 4, color: category === 'logs' ? '#ff6700' : '', cursor: 'pointer' }}>User logs</span>
                <span
                    onClick={() => setCategory('transactions')}
                    style={{ marginRight: 4, color: category === 'transactions' ? '#ff6700' : '', cursor: 'pointer' }}>User Transactions</span>
                <span
                    onClick={() => setCategory('loans')}
                    style={{ marginRight: 4, color: category === 'loans' ? '#ff6700' : '', cursor: 'pointer' }}>Loans</span>
                <span
                    onClick={() => setCategory('pos')}
                    style={{ marginRight: 4, color: category === 'pos' ? '#ff6700' : '', cursor: 'pointer' }}>POS</span>
            </div>
            <hr />
            <div className="flex flex-row">
                <Button onClick={handleSubmit} className={styles.menuButton}>
                    <EditFilled />
                    Edit
                </Button>
                <Button onClick={handleSubmit} className={styles.menuButton}>
                    New Loan
                </Button>
                <Button onClick={handleSubmit} className={styles.menuButton}>
                    New Saving
                </Button>
                <Button onClick={handleSubmit} className={styles.menuButton}>
                    Add Charge
                </Button>
                <Button onClick={handleSubmit} className={styles.menuButton}>
                    Transfer User
                </Button>
                <Button onClick={handleSubmit} className={styles.menuButton}>
                    New Saving
                </Button>
            </div>
          </div>
  
        </div>
        {category === 'general' ? (
          <div className="container my-2" style={{  width:'100%'}}>
            <div className="row" style={{ width:'100%'}}>
                <div className="col-8"style={{  width:'70%'}}>
                  <div>
                    <p  className={styles.title} style={{ fontSize: 14, marginTop: 0}}> Account Overview</p>
                    {wallets?.map(e =>
                    <table className="table" style={{ fontSize: 12}}>
                        <thead>
                            <th>Account Number</th>
                            <th>Account Type</th>
                            <th>Last Active</th>
                            <th>Balance</th>
                        </thead>
                        <tbody>
                            <tr style={{ background: '#fff'}}>
                                <td>{e?.accountNo}</td>
                                <td>{e?.product_type}</td>
                                <td>{e?.last_tran_date}</td>
                                <td>{currencyFormat(e?.clr_bal_amt)}</td>
                            </tr>
                        </tbody>
                    </table>
                  )}
                  </div>
                  <div>
                    <p className={styles.title} style={{ fontSize: 14}}> Loan Overview</p>
                    <div className="card">
                      <h6>Loan Type</h6>
                     
                    </div>
                  </div>
                  <div>
                    <p className={styles.title} style={{ fontSize: 14}}> Card Overview</p>
                    <table className="table" style={{ fontSize: 12}}>
                        <thead>
                            <th>PAN Number</th>
                            <th>CCV</th>
                            <th>Expiry Date</th>
                            <th>Last Used</th>
                        </thead>
                        <tbody>
                            <tr style={{ background: '#fff'}}>
                                <td>336474 474884</td>
                                <td>988</td>
                                <td>05/26</td>
                                <td>10.57</td>
                            </tr>
                        </tbody>
                    </table>
                  </div>
                  <div>
                    <p className={styles.title} style={{ fontSize: 14}}> POS Overview</p>
                    <table className="table" style={{ fontSize: 12}}>
                        <thead>
                            <th>POS Terminal</th>
                            <th>Terminal Id</th>
                            <th>Account Number</th>
                            <th>Last Used</th>
                        </thead>
                        <tbody>
                            <tr style={{ background: '#fff'}}>
                                {/* <td>NEXGO</td>
                                <td>W9888YP</td>
                                <td>2093745241</td>
                                <td>10.57</td> */}
                            </tr>
                        </tbody>
                    </table>
                  </div>
                </div>
                <div className="card" style={{  width:'30%'}}>
                    <div className={styles.tDLTextTop}>{userData?.surname?.toUpperCase()} {userData?.firstName?.toUpperCase()}</div>
                    <div className={styles.tDL}>
                      <img src={user?.profileImage?.length > 0 ? user?.profileImage : UserIcon} alt="user" className='img-fluid rounded-circle' width={90} height={50}/>
                    </div>
                    <div style={{ marginTop: 5}}>
                      <h6 style={{ fontWeight: 'normal', fontSize: 13, }}>First Name: 
                        <span style={{ fontWeight: 'bold', marginLeft: 2}}>
                          {user?.firstName}
                        </span>
                      </h6>
                      <h6 style={{ fontWeight: 'normal', fontSize: 13}}>Last Name: 
                        <span style={{ fontWeight: 'bold', marginLeft: 2}}>
                          {user?.surname}
                        </span>
                      </h6>
                      <h6 style={{ fontWeight: 'normal', fontSize: 13}}>Email Address: 
                        <span style={{ fontWeight: 'bold', marginLeft: 2}}>
                          {user?.email}
                        </span>
                      </h6>
                      <h6 style={{ fontWeight: 'normal', fontSize: 13}}>Phone Number: 
                        <span style={{ fontWeight: 'bold', marginLeft: 2}}>
                          {user?.phoneNumber}
                        </span>
                      </h6>
                      <h6 style={{ fontWeight: 'normal', fontSize: 13}}>Referral Code: 
                        <span style={{ fontWeight: 'bold', marginLeft: 2}}>
                          {user?.referenceCode}
                        </span>
                      </h6>
                      <h6 style={{ fontWeight: 'normal', fontSize: 13}}>Support Code: 
                        <span style={{ fontWeight: 'bold', marginLeft: 2}}>
                          {user?.supportCode ?? 'N/A'}
                        </span>
                      </h6>
                      <h6 style={{ fontWeight: 'normal', fontSize: 13}}>Date Created: 
                        <span style={{ fontWeight: 'bold', marginLeft: 2}}>
                          {user?.dateCreated ?? 'N/A'}
                        </span>
                      </h6>
                      <h6 style={{ fontWeight: 'normal', fontSize: 13}}>Type of User: 
                        <span style={{ fontWeight: 'bold', marginLeft: 2}}>
                          {user?.corporate ? 'Corporate' : 'Personal'} Account
                        </span>
                      </h6>
                      <h6 style={{ fontWeight: 'normal', fontSize: 13}}>KYC Level: 
                        <span style={{ fontWeight: 'bold', marginLeft: 2}}>
                          {volume?.tierName ?? 'N/A'}
                        </span>
                      </h6>
                      <h6 style={{ fontWeight: 'normal', fontSize: 13}}>BVN: 
                        <span style={{ fontWeight: 'bold', marginLeft: 2}}>
                          {bvnData?.tier2 ? bvnData?.tier2[0]?.verificationReference : 'N/A'}
                        </span>
                      </h6>
                      <h6 style={{ fontWeight: 'normal', fontSize: 13}}>Daily Transaction Limit: 
                        <span style={{ fontWeight: 'bold', marginLeft: 2}}>
                          {volume?.transactionLimit ? currencyFormat(volume?.transactionLimit) : 'N/A'}
                        </span>
                      </h6>
                      <h6 style={{ fontWeight: 'normal', fontSize: 13}}>Daily Transaction Count: 
                        <span style={{ fontWeight: 'bold', marginLeft: 2}}>
                          {volume?.transactionCount ?? 'N/A'}
                        </span>
                      </h6>
                      <h6 style={{ fontWeight: 'normal', fontSize: 13}}>Last Transacted:
                        <span style={{ fontWeight: 'bold', marginLeft: 2}}>
                          {volume?.lastTran ?? 'N/A'}
                        </span>
                      </h6>
                    </div>
                </div>                
            </div>
        </div>
        ) : category === 'pricing' ? (
          <div className="container my-2" style={{  width:'100%'}}>
            <div className="card px-5 py-5 " style={{ width: '80%'}}>
            <h6>List of Products and Pricing</h6>
              <div className="flex flex-row">
              {products?.map(e=>
                  <div className={`${styles.productsList} col-6 py-4`}>
                    <h6 style={{ fontSize: 12, marginBottom: 2}}>{e?.product}</h6>
                    <span style={{ fontWeight: 'bold', fontSize: 12}}>
                      {e?.customAmount ?e?.customAmount : e?.generalAmount}
                    </span>
                  </div>
                )}
                </div>
            </div>
          </div>
        ): category ==='transactions'? (
          <UserTransactionHistory />
        ) : category === 'logs' ? (
          <div>
            {logs.length>0?<DataGrid
            className={styles.table}
            rows={logs}
            density="comfortable"
            columns={columns}
            page={pagination.current}
            // paginationMode="server"
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={metaData}
          />:<div>
              No Transaction Found
            </div>}
          </div>
        ) : (
          ''
        )}
        {/* <div className={styles.bottomDiv}>
          <div className={styles.inputWrap}>
            <div >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div >
                  <DesktopDatePicker
                    label="Date of Birth"
                    inputFormat="MM/dd/yyyy"
                    value={userData.dateOfBirth}
                    onChange={(value) =>{
                      console.log('DOB', moment(value).format('YYYY-MM-DD'));
                      setUserData({ ...userData, dateOfBirth: moment(value).format('YYYY-MM-DD') })
                    }}
                    renderInput={(params) => (
                      <TextField className={styles.datePicker} {...params} />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Usertype</div>
              <Input
                disabled
                value={userType == true ? "Corporate" : "Individual"}
                onChange={(event) => setUserType(event.target.value)}
                className={styles.inputInput}
              />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Status</div>
              <Input value={status == true ? "Active" : "Inactive"} disabled className={styles.inputInput} />
            </div>
          </div>
          <div className={styles.inputWrap}>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>First Name</div>
              <Input
                value={userData.firstName}
                onChange={(e) => setUserData({...userData, firstName: e?.target?.value})}
                className={styles.inputInput}
              />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Last Name</div>
              <Input
                value={userData.surname}
                onChange={(e) => setUserData({...userData, surname: e?.target?.value})}
                className={styles.inputInput}
              />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Email Address</div>
              <Input
                value={userData.email}
                onChange={(e) => setUserData({...userData, email: e?.target?.value})}
                className={styles.inputInput}
              />
            </div>
          </div>
          <div className={styles.inputWrap}>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Phone Number</div>
              <Input
                value={userData.phoneNumber}
                onChange={(e) => setUserData({...userData, phoneNumber: e?.target?.value})}
                className={styles.inputInput}
              />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Reference Code</div>
              <Input
                value={referenceCode}
                disabled
                onChange={(evt) => setReferenceCode(evt.target.value)}
                className={styles.inputInput}
              />
            </div>
            <div className={styles.inputCont}>
              <div
                className={styles.inputInput}
                style={{ height: "42px", marginTop: "22px" }}
              >
                <span> SMS Alert</span>
                <Switch
                  style={{ float: "right" }}
                  checked={smsAlertConfig}
                  onChange={handleSmsAlertConfigChange}
                />
              </div>

            </div>
          </div>
          <div className={styles.inputWrap}>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Gender</div>
              <Input
                disabled
                value={userData.gender}
                onChange={(evt) =>
                  setUser({ ...userData, gender: evt.target.value })
                }
                className={styles.inputInput}
              />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>User ID</div>
              <Input value={userId} disabled className={styles.inputInput} />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Current Tier Level</div>
              <Input value={kyc?.kycTier?.orderLevel??'Not set'} disabled className={styles.inputInput} />
            </div>
            </div>
            <div className={styles.inputWrap}>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Transaction Processing Status</div>
              <Input  disabled className={styles.inputInput} />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Account Block Status</div>
              <Input  disabled className={styles.inputInput} />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Maximum Daily Transaction Limit</div>
              <Input value={volume} onChange={(e)=>{
                setVolume(e.target.value)}}  className={styles.inputInput} />
            </div>
            </div>
            <div className={styles.inputWrap}>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Maximum Daily Transaction Count</div>
              <Input value={count} onChange={(e)=>{
                setCount(e.target.value)}}  className={styles.inputInput} />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>BVN</div>
              <Input value={/*`${/* bvnData?.tier2[0] ? bvnData?.tier2[0]?.verificationReference : ''}
                - ${bvnData?.tier2[0] ? bvnData?.tier2[0]?.status : ''}`''} disabled className={styles.inputInput} />
                
            </div>
          </div>
          <div className={styles.inputWrap}>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Maximum Transfer Limit</div>
              <Input disabled  value={kyc?.kycTier?.dailyTransactionLimit??''} className={styles.inputInput} />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>No. Transaction Allowed</div>
              <Input disabled value={kyc?.kycTier?.dailyTransactionLimitCount??''} className={styles.inputInput} />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Single Traansfer Limit</div>
              <Input disabled value={kyc?.kycTier?.singleTransactionLimit??''} className={styles.inputInput} />
            </div>
            </div>


            <div className={styles.inputWrap}>
            <div className={styles.inputCont}>
              <div
                className={styles.inputInput}
                style={{ height: "42px", marginTop: "22px" }}
              >
                <span> Bills Payment</span>
                <Switch
                  style={{ float: "right" }}
                  checked={tranStatus?.billsPayment}
                  onChange={()=>configTran('Bills')}
                />
              </div>
            </div>
            <div className={styles.inputCont}>
              <div
                className={styles.inputInput}
                style={{ height: "42px", marginTop: "22px" }}
              >
                <span> Withdrawal</span>
                <Switch
                  style={{ float: "right" }}
                  checked={tranStatus?.withdrawal}
                  onChange={()=>configTran('Withdrawal')}
                />
              </div>
            </div>
            <div className={styles.inputCont}>
              <div
                className={styles.inputInput}
                style={{ height: "42px", marginTop: "22px" }}
              >
                <span> Deposit</span>
                <Switch
                  style={{ float: "right" }}
                  checked={tranStatus?.deposit}
                  onChange={()=>configTran('Deposit')}
                />
              </div>
            </div>
          </div>
             {user?.otherDetails && <>
              <div className={styles.inputWrap}>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Organization Name</div>
              <Input 
              onChange={(e) => setUserData({...userData, organisationName: e?.target?.value})}
              value={userData?.organisationName} className={styles.inputInput} />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Organization Email</div>
              <Input  
              onChange={(e) => setUserData({...userData, organisationEmail: e?.target?.value})}
              value={userData?.organisationEmail} className={styles.inputInput} />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Organization Phone</div>
              <Input 
              onChange={(e) => setUserData({...userData, organisationPhone: e?.target?.value})}
              value={userData?.organisationPhone}   className={styles.inputInput} />
            </div>
            </div>
            
            <div className={styles.inputWrap}>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Organization City</div>
              <Input
                onChange={(e) => setUserData({...userData, organizationCity: e?.target?.value})}
                value={userData?.organizationCity} className={styles.inputInput} />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Organization Address</div>
              <Input  
                onChange={(e) => setUserData({...userData, officeAddress: e?.target?.value})}
                value={userData?.officeAddress} className={styles.inputInput} />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Organization State</div>
              <Input 
                onChange={(e) => setUserData({...userData, organizationState: e?.target?.value})}
                value={userData?.organizationState}   className={styles.inputInput} />
            </div>
            </div>
            <div className={styles.inputWrap}>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Business Type</div>
              <select 
                className={styles.inputInput}
                value={userData?.organisationType} onChange={(e) => setUserData({...userData, organisationType: e?.target?.value})}>
                {
                 businessType?.length > 0 && businessType?.map(e=>
                    <option value={e?.businessType}>{e?.businessType}</option>
                    )
                }
              </select>
            </div>
            
            </div>
            </>}
            
          <Button onClick={handleSubmit} className={styles.updateButton}>
            Update User
          </Button>
        </div> */}
      </EditWRapper>
    </Layout>
  );
};

export default ProfilePage;
