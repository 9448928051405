import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(() => {
  return {
    updateButton: {
      borderRadius: "4px !important",
      textAlign: "center",
      background: "#ff6700",
      float: "right",
      marginTop: "-18px",
      fontSize: "20px",
      color: "#fff",
      height: "60px",
      "&:hover": {
        background: "#FF4B01",
        color: "#fff",
      },
      "&:focus": {
        background: "#FF4B01",
        color: "#fff",
      },
    },
    menuButton: {
      borderRadius: "4px !important",
      textAlign: "center",
      background: "",
      float: "left",
      marginTop: "2px",
      fontSize: "12px",
      color: "gray",
      height: "30px",
      paddingLeft: "4px",
      paddingRight: "4px",
      "&:hover": {
        background: "#FF4B01",
        color: "#fff",
      },
      "&:focus": {
        background: "#FF4B01",
        color: "#fff",
      },
    },
    productsList: {
      borderRadius: "4px !important",
      textAlign: "start",
      background: "",
      float: "left",
      marginTop: "2px",
      marginBottom: "2px",
      fontSize: "12px",
      color: "gray",
      height: "30px",
      paddingLeft: "4px",
      paddingRight: "4px",
    },
   
    topDiv: {
      display: "flex",
      flexDirection: "column",
      borderBottom: "1px solid #C4C4C4",
      paddingBottom: "27px",
      marginBottom: "45px",
    },
    title: {
      fontWeight: "bold",
      fontSize: "24px",
      lineHeight: "29px",
      color: "#000000",
      marginBottom: "10px",
      marginTop: "20px"
    },
    titleDet: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    grow: {
      flexGrow: 1,
    },
    tDL: {
      display: "flex",
      alignItems: "center",
      textAlign: "center"
    },
    tDlIcon: {
      marginRight: "16px",
      marginTop: "15px",
    },
    tDLTextTop: {
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: "22px",
      color: "#000000",
      marginBottom: "5px",
    },
    tDLTextBot: {
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "17px",
      color: "#6A6A6A",
    },
    bottomDiv: {
      paddingRight: "100px",
    },
    datePicker: {
      marginTop: "10px",
      height: "10%",
      width: "285px",
    },
    inputWrap: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "35px",
    },
    inputCont: {
      dispaly: "flex",
      width: "32%",
      flexDirection: "column",
    },
    inputLabel: {
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "12px",
      color: "#000000",
      marginBottom: "9px",
    },
    inputInput: {
      background: "#FFFFFF",
      border: "1px solid #000000",
      boxSizing: "border-box",
      borderRadius: "5px !important",
      width: "100%",
      padding: "8px !important",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "12px",
      color: "#000000",
    },
    footer: {
      display: "flex",
      flexDirection: "column",
    },
    fTop: {
      marginBottom: "15px",
    },
    fBot: {
      display: "flex",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "12px",
      color: "#000000",
    },
    postCardWrap: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      paddingRight: "100px",
    },
    postCard: {
      height: "160px",
      width: "32%",
      background: "#FFFFFF",
      boxShadow: "0px 0px 26px rgba(0, 0, 0, 0.13)",
      borderRadius: "12px",
      marginBottom: "25px",
      padding: "15px",
      display: "flex",
      flexDirection: "column",
    },
  };
});

export default useStyles;
