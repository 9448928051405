import React, { useEffect, useState } from "react";
import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  ListItem,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {useMatStyles} from "../style";
import PropTypes from "prop-types";
// import { useAutocomplete } from "@mui/base/AutocompleteUnstyled";
// import CheckIcon from "@mui/icons-material/Check";
// import CloseIcon from "@mui/icons-material/Close";
import { Button, Divider } from "antd";
import { customBaseUrl, httpGet, httpPost, httpPut } from "../../../../store/http";
import { NotificationManager } from "react-notifications";
import { httpPostFormData } from "../../../../services/http";
import { hideLoader, showLoader } from "../../../../utils/loader";
import Swal from "sweetalert2";


const initial ={
    identityType: null,
    processType: null,
    isActive: true,
}
const AddKycProvider = ({
  showModal,
  setShowModal,

  getProviders,
  
}) => {
  const styles = useMatStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [comLists, setComList] = React.useState({})
  const [genComList, setGenComList] = React.useState('')
  const [agentCom, setAgentCom] = React.useState(0)
  const [aggregatorCom, setAggregatoCom] = React.useState(0)
  const [data, setData] = useState(initial)


  useEffect(() => {
   
  }, []);

 

  const handleSubmit = async (e) => {
    e.preventDefault();
    showLoader()
   
    let res = await httpPost(`/api/v1/provider/create/${data?.name}/${data?.isActive}?identityType=${data?.identityType}&processType=${data?.processType}`,
    data,
    customBaseUrl.kycUrl
    )

    if(res.status){
      hideLoader();
      setShowModal(false)
      getProviders();
      Swal.fire('Done', res?.message)
    }else{
      hideLoader();
      Swal.fire('error', res?.message)
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };
  const idType=['ALL','ACCOUNT_STATEMENT', 'BVN', 'CAC', 'DRIVER_LICENSE', 'EMAIL', 'ID', 'INTERNATIONAL_PASSPORT', 'NIN', 'PHONE_NUMBER', 'PHOTO', 'REFERENCE', 'TIN', 'UTILITY', 'VOTERS_CARD']
  const processType=['ADVANCE', 'BASIC', 'GLOBAL']
  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        maxWidth='md'
        open={showModal}
        onClose={() => handleClose()}
      >
        <DialogTitle style={{ textAlign: "center" }}>
          Create KYC Provider
        </DialogTitle>
        <DialogContent>
        <form onSubmit={handleSubmit}>
          <div className="col">
          <div className="col-md-12">
              <div className={styles.inputCont}>
                <div className={`${styles.inputLabel}`}>Provider Name</div>
                <input
                required
                  value={data?.name}
                  onChange={(e)=>setData({...data, name: e?.target?.value})}
                  className={`{styles.inputInput} border input-content`}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className={styles.inputCont}>
                <div className={`${styles.inputLabel}`}>Process Type</div>
                <select
                  value={data?.processType}
                  onChange={(e)=>setData({...data, processType: e?.target?.value})}
                  className={`{styles.inputInput} border input-content`}
                >
                 <option>select Process Type</option>                  
                    {processType?.map(e=> 
                    <option value={e}>
                      {e}
                    </option>
                    )}
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className={styles.inputCont}>
                <div className={`${styles.inputLabel}`}>Identity Type</div>
                <select
                  value={data?.identityType}
                  onChange={(e)=>setData({...data, identityType: e?.target?.value})}
                   className={`{styles.inputInput} border input-content`}
                >
                  <option>select Identity Type</option>                  
                    {idType?.map(e=> 
                    <option value={e}>
                      {e}
                    </option>
                    )}
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className={styles.inputCont}>
                <div className={`${styles.inputLabel}`}>Status</div>
                <select
                    required
                   value={data?.isActive}
                   onChange={(e)=>setData({...data, isActive: e?.target?.value})}
                  className={`{styles.inputInput} border input-content`}
                >
                    <option>
                      Select Status
                    </option>
                    <option value={true}>
                      Active
                    </option>
                    <option value={false}>
                      InActive
                    </option>
                </select>
              </div>
            </div>
          </div>
          <Divider />
          <div className="row">
          
            <div className="col-md-12">
              <button type="submit" className='btn' style={{background: '#ff6700', color: '#fff', textAlign: 'center'}}>
                Save
              </button>
            </div>
          </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default AddKycProvider;
